/* Add a black background color to the top navigation */
.topnav {
    background-color: #faf7f7;
    overflow: hidden;
    text-align: center;
    top: 60px;
    font-family:"Sans Serif";
  }
  
  /* Style the links inside the navigation bar */
  .topnav a {
    float: center;
    display: inline-flex;
    color: #0905f8;
    text-align: center;
    padding: 24px 26px;
    text-decoration: none;
    font-size: 15px;
    font-family:"Sans Serif";
  }

  /* Estilo para boton de inicio de sesion */
  .sesion {
    position: relative;
    display:flexbox;
    color: #0905f8;
    text-align: right;
    padding: 24px 26px;
    text-decoration:#0905f8;
    font-size: 15px;
    font-family:"Sans Serif";
  }

  .boton {
    color: #0905f8;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    background-color: #f8b706;
    color: white;
  }
 